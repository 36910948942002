import { ActionReducerMapBuilder } from "@reduxjs/toolkit";
import { AuthState } from "../authReducer";
import { emailVerifyAction } from "./emailVerificationActions";

export interface EmailVerificationState {
  isLoading: boolean;
  success: boolean;
  error?: string;
}

export const emailVerificationInitialState: EmailVerificationState = {
  isLoading: false,
  success: false,
  error: undefined,
};

export const emailVerificationReducers = (
  builder: ActionReducerMapBuilder<AuthState>
) =>
  builder
    .addCase(emailVerifyAction.pending, (state) => {
      state.emailVerification.isLoading = true;
    })
    .addCase(emailVerifyAction.fulfilled, (state) => {
      state.emailVerification.isLoading = false;
      state.emailVerification.success = true;
    })
    .addCase(emailVerifyAction.rejected, (state, action) => {
      state.emailVerification.error = action.payload;
      state.emailVerification.isLoading = false;
    });
