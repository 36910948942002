import { AppDownloadButtons } from "components/LandingPage";
import { APP_INTRO_INFO } from "./constants";
import styles from "./AppIntro.module.css";

interface Props {
  variant?: "default" | "get-started";
  style?: string;
}

const AppIntro = ({ variant = "default", style }: Props) => {
  const isGetStarted = variant === "get-started";
  const finalStyle = `${styles.container} ${
    isGetStarted && styles.containerReversed
  } ${style}`;
  const finalContentInfoStyle = `${styles.contentInfo} ${
    isGetStarted && styles.contentInfoReversed
  }`;
  const { title, subtitle, icon } = APP_INTRO_INFO[variant];

  return (
    <div className={finalStyle}>
      <div className={finalContentInfoStyle}>
        <div className={styles.contentTitle}>{title}</div>
        <div className={styles.contentSubtitle}>{subtitle}</div>
        <AppDownloadButtons />
      </div>
      <img className={styles.mobileFrame} src={icon} alt="" />
    </div>
  );
};

export default AppIntro;
