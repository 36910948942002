import { Provider } from "react-redux";
import "./App.css";
import "./res/colors/colors.css";
import MainRouter from "routes";
import { store } from "store";
import "./utils/fbAnalytics";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <MainRouter />
      </Provider>
    </div>
  );
}

export default App;
