import { Pngs } from "res";
import styles from "./CompaniesDisplay.module.css";
import { useMemo } from "react";
import { ScreenSizes } from "types/screen";

interface Props {
  screenWidth: number;
}

const CompaniesDisplay = ({ screenWidth }: Props) => {
  const companiesList = useMemo(() => {
    const companiesLogos = Pngs.CompaniesLogos;
    let companiesList: string[] = [];
    for (const key in companiesLogos) {
      if (Pngs.CompaniesLogos.hasOwnProperty(key)) {
        const url = companiesLogos[key as keyof typeof companiesLogos];
        companiesList.push(url);
      }
    }
    return companiesList;
  }, []);

  const plainRow = useMemo(
    () => (screenWidth >= ScreenSizes.TABLET ? companiesList : undefined),
    [companiesList, screenWidth]
  );

  const columns = useMemo(() => {
    if (!plainRow) {
      return {
        firstColumn: companiesList.slice(0, 2),
        secondColumn: companiesList.slice(2, 4),
        thirdColumn: companiesList.slice(4, 6),
      };
    }
  }, [companiesList, plainRow]);

  const renderRow = plainRow
    ? plainRow.map((logo) => {
        return (
          <img
            key={`row-company-logo-${logo}`}
            className={styles.companyLogo}
            src={logo}
            alt=""
          />
        );
      })
    : undefined;

  const renderColumns = (column: string[]) => {
    return column.map((logo, index) => {
      const finalStyle =
        index !== 0
          ? `${styles.companyLogo} ${styles.companyLogoMarginBottom}`
          : styles.companyLogo;

      return (
        <img
          key={`column-company-logo-${logo}`}
          className={finalStyle}
          src={logo}
          alt=""
        />
      );
    });
  };

  if (renderRow) {
    return (
      <div className={styles.container}>
        {renderRow && <div className={styles.content}>{renderRow}</div>}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {columns?.firstColumn && (
          <div className={styles.columnContainer}>
            {renderColumns(columns.firstColumn)}
          </div>
        )}
        {columns?.secondColumn && (
          <div className={styles.columnContainer}>
            {renderColumns(columns.secondColumn)}
          </div>
        )}
        {columns?.thirdColumn && (
          <div className={styles.columnContainer}>
            {renderColumns(columns.thirdColumn)}
          </div>
        )}
      </div>
    </div>
  );
};

export default CompaniesDisplay;
