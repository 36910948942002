import { useParams } from "react-router-dom";
import EmailVerificationScreenComponent from "./EmailVerificationScreen.component";
import { useEffect } from "react";
import { IRootState, useAppDispatch } from "store";
import { emailVerifyAction } from "../../../../store/slices/auth/emailVerification/emailVerificationActions";
import { useSelector } from "react-redux";

const EmailVerificationScreen = () => {
  const dispatch = useAppDispatch();
  const { token } = useParams();
  const { isLoading, success, error } = useSelector(
    (state: IRootState) => state.auth.emailVerification
  );

  useEffect(() => {
    if (token) {
      dispatch(emailVerifyAction({ token }));
    }
  }, [dispatch, token]);

  const onBackToAppClick = () => {
    window.location.href = `snapshelf://signup-verification/success`;
  };

  return (
    <EmailVerificationScreenComponent
      isLoading={isLoading}
      error={error}
      success={success}
      onBackToAppClick={onBackToAppClick}
    />
  );
};

export default EmailVerificationScreen;
