import styles from "./AppExplain.module.css";
import AppExplainBlock from "./components/AppExplainBlock";
import { explainTitleSubtitle, explains, ratings } from "./constants";
import { ExplainBlockVariant } from "./types";

interface Props {
  variant?: ExplainBlockVariant;
}

const AppExplain = ({ variant = "explain" }: Props) => {
  const { id, title, subtitle } = explainTitleSubtitle[variant];
  const finalExplains = variant === "explain" ? explains : ratings;

  const renderExplainBlock = finalExplains.map((explain) => {
    const key = `explain-block-index-${explain.index}`;

    return <AppExplainBlock key={key} variant={variant} {...explain} />;
  });

  return (
    <div id={id} className={styles.container}>
      <div className={styles.title}>{title}</div>
      <div className={styles.subtitle}>{subtitle}</div>
      <div className={styles.explainBlocksContainer}>{renderExplainBlock}</div>
    </div>
  );
};

export default AppExplain;
