import styles from "./BottomInfoBar.module.css";

const TERMS_BUTTON_TEXT = "Terms and Conditions";
const PRIVACY_BUTTON_TEXT = "Privacy Policy";
const CONTACT_BUTTON_TEXT = "Contact Us";

const BottomInfoBar = () => {
  const onTermsPress = () => {
    window.open("/tos", "_blank");
  };

  const onPrivacyPress = () => {
    window.open("/privacy", "_blank");
  };

  const onContactPress = () => {
    window.open("/contact-us", "_blank");
  };

  return (
    <div className={styles.container}>
      <div className={styles.buttonsContainer}>
        <div className={styles.text} onClick={onTermsPress}>
          {TERMS_BUTTON_TEXT}
        </div>
        <div className={styles.verticalLine} />
        <div className={styles.text} onClick={onPrivacyPress}>
          {PRIVACY_BUTTON_TEXT}
        </div>
        <div className={styles.verticalLine} />
        <div className={styles.text} onClick={onContactPress}>
          {CONTACT_BUTTON_TEXT}
        </div>
      </div>
      <div className={styles.copyright}>© 2023 SnapShelf.ai </div>
    </div>
  );
};

export default BottomInfoBar;
