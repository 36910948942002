import { API } from "./API";

export const verifySignupEmail = async (token: string) => {
  return await API.post(
    "/api/v1/auth/email-verify",
    {
      data: { token },
    },
    false
  );
};
