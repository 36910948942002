import { Pngs } from "res";

export const APP_INTRO_INFO = {
  default: {
    title: "Personalized Book Recommendations",
    subtitle:
      "Take a photo of your bookshelf. We’ll tell you what you’re missing.",
    icon: Pngs.IntroImage,
  },
  "get-started": {
    title: "Get SnapShelf Today!",
    subtitle:
      "Build your list of favorites by taking a photo of your bookshelf. You can also enter them manually with text if you prefer. ",
    icon: Pngs.IntroImage2,
  },
};
