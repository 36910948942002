const GooglePlaySmallIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/companies/google_play.png";
const AndroidSmallIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/companies/android.png?v=4";
const AWSSmallIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/companies/aws.png";
const AppleSmallIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/companies/apple.png";
const ChatGPTSmallIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/companies/chatgpt.png";
const MicrosoftSmallIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/companies/microsoft.png";

export const CompaniesLogos = {
  GooglePlaySmallIcon,
  AndroidSmallIcon,
  AWSSmallIcon,
  AppleSmallIcon,
  ChatGPTSmallIcon,
  MicrosoftSmallIcon,
};
