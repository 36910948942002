import { useMemo } from "react";
import NavigationRouteButton from "./components/NavigationRouteButton";
import { useLocation, useNavigate } from "react-router-dom";
import {
  navigationRouteButtons,
  navigationRouteButtonsTablet,
} from "./constants";
import { Svgs } from "res";
import styles from "./NavigationBar.module.css";
import { ScreenSizes } from "types/screen";
import { EXPLAIN_HEADER_ID } from "screens/LandingPage/LandingScreen/components/AppExplain/constants";

interface Props {
  screenWidth: number;
}

const NavigationBar = ({ screenWidth }: Props) => {
  const navigate = useNavigate();
  const location = useLocation();

  const finalNavigationRouteButtons = useMemo(() => {
    if (screenWidth > ScreenSizes.TABLET) {
      return navigationRouteButtons;
    } else if (screenWidth > ScreenSizes.MOBILE && screenWidth > 500) {
      return navigationRouteButtonsTablet;
    } else {
      return [];
    }
  }, [screenWidth]);

  const goToHome = () => {
    navigate("/");
  };

  const renderNavigationRouteButtons = useMemo(
    () =>
      finalNavigationRouteButtons.map((navigationRouteButton) => {
        const { id, label, Icon, type, buttonVariant } = navigationRouteButton;
        const finalStyle = `${
          type === "text"
            ? styles.navigationTextButton
            : styles.navigationButton
        }`;
        const onClick = () => {
          if (navigationRouteButton.route === "/learn-more") {
            if (location.pathname === "/") {
              const element = document.getElementById(EXPLAIN_HEADER_ID);
              if (element) {
                element.scrollIntoView({ behavior: "smooth", block: "start" });
              }
            } else if (location.pathname === "/blog") {
              navigate("/");
            }
          }
          navigate(navigationRouteButton.route);
        };

        return (
          <NavigationRouteButton
            key={`navigation-route-button-${id}`}
            style={finalStyle}
            label={label}
            Icon={Icon}
            type={type}
            variant={buttonVariant}
            onClick={onClick}
          />
        );
      }),
    [finalNavigationRouteButtons, location.pathname, navigate]
  );

  return (
    <div className={`${styles.container}`}>
      <div className={styles.logoContainer}>
        <img
          className={styles.ssLogo}
          src={Svgs.SSLogo}
          alt=""
          onClick={goToHome}
        />
        <img
          className={styles.simpleLogo}
          src={Svgs.SimpleLogo}
          width={160}
          alt=""
          onClick={goToHome}
        />
      </div>
      <div className={styles.navigationButtonsContainer}>
        {renderNavigationRouteButtons}
      </div>
    </div>
  );
};

export default NavigationBar;
