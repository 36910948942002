import { SSScreen } from "components/LandingPage";
import styles from "./ContactScreen.module.css";

const CONTACT_EMAIL = "support@snapshelf.ai";

const ContactScreen = () => {
  const onEmailPress = () => {
    window.location.href = `mailto:${CONTACT_EMAIL}`;
  };
  return (
    <SSScreen>
      <div className={styles.content}>
        <b>Support email:</b>
        <div className={styles.link} onClick={onEmailPress}>
          {CONTACT_EMAIL}
        </div>
      </div>
    </SSScreen>
  );
};

export default ContactScreen;
