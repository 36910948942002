import { ActionButton, TextButton } from "components/LandingPage";

interface Props {
  label: string;
  Icon?: React.ReactNode;
  type: "text" | "button";
  variant?: "default" | "pink";
  onClick: () => void;
  style?: string;
  labelStyle?: string;
}

const NavigationRouteButton = ({
  label,
  Icon,
  type,
  variant,
  onClick,
  style,
  labelStyle,
}: Props) => {
  if (type === "text") {
    return (
      <TextButton
        style={style}
        labelStyle={labelStyle}
        label={label}
        onClick={onClick}
      />
    );
  }
  return (
    <ActionButton
      style={style}
      labelStyle={labelStyle}
      variant={variant}
      label={label}
      Icon={Icon}
      onClick={onClick}
    />
  );
};

export default NavigationRouteButton;
