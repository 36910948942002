import { ActionButton, LoadingSpinner, SSScreen } from "components/LandingPage";
import cssStyles from "./EmailVerificationScreen.module.css";
import { styles } from "./EmailVerificationScreen.style";
import { Pngs } from "res";

const VERIFICATION_TITLE_SUCCESS = "Success!";
const VERIFICATION_TITLE_FAILED = "Failed!";

const VERIFICATION_MESSAGE_SUCCESS =
  "You can now log in with email and password.";
const VERIFICATION_BACK_TO_APP = "Open app";

interface Props {
  isLoading: boolean;
  success: boolean;
  onBackToAppClick: () => void;
  error?: string;
}

const EmailVerificationScreenComponent = ({
  isLoading,
  success,
  onBackToAppClick,
  error,
}: Props) => {
  const finalMessageStyle = `${cssStyles.message} ${
    error ? cssStyles.messageError : cssStyles.messageSuccess
  }`;
  const finalTitleText = success
    ? VERIFICATION_TITLE_SUCCESS
    : VERIFICATION_TITLE_FAILED;
  const finalMessageText = success ? VERIFICATION_MESSAGE_SUCCESS : error;

  return (
    <SSScreen>
      <div className={cssStyles.content}>
        {isLoading ? (
          <LoadingSpinner style={styles.loadingSpinner} size={40} />
        ) : (
          <>
            <div className={cssStyles.title}>{finalTitleText}</div>
            <div className={finalMessageStyle}>{finalMessageText}</div>
          </>
        )}
        {success && (
          <ActionButton
            style={cssStyles.actionButton}
            label={VERIFICATION_BACK_TO_APP}
            onClick={onBackToAppClick}
          />
        )}
        <img src={Pngs.SSBigLogo} width={234} alt="" />
      </div>
    </SSScreen>
  );
};

export default EmailVerificationScreenComponent;
