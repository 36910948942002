const downloadCTABigIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/cta/downloadCTABig.png";
const downloadCTAMediumIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/cta/downloadCTAMedium.png";
const downloadCTASmallIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/cta/downloadCTASmall.png";

export const downloadCTAIcons = {
  downloadCTABigIcon,
  downloadCTAMediumIcon,
  downloadCTASmallIcon,
};
