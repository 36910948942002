import { AppDownloadButtons } from "components/LandingPage";
import { Pngs } from "res";
import { CTAtexts } from "../../constants";
import styles from "./DownloadCTASmall.module.css";

const DownloadCTASmall = () => {
  return (
    <div className={styles.container}>
      <img
        className={styles.background}
        src={Pngs.downloadCTAIcons.downloadCTASmallIcon}
        alt="CTA"
      />
      <div className={styles.content}>
        <div className={styles.title}>{CTAtexts.title}</div>
        <div className={styles.subtitle}>{CTAtexts.subtitle}</div>
        <AppDownloadButtons variant="small" style={styles.downloadButtons} />
      </div>
    </div>
  );
};

export default DownloadCTASmall;
