import { createAsyncThunk } from "@reduxjs/toolkit";
import { verifySignupEmail } from "api/authAPI";

export const emailVerifyAction = createAsyncThunk<
  undefined,
  { token: string },
  { rejectValue: string }
>(
  "auth/emailVerification/emailVerifyAction",
  async (data, { rejectWithValue }) => {
    try {
      await verifySignupEmail(data.token);
    } catch (err: any) {
      return rejectWithValue(err.message as string);
    }
  }
);
