import { BottomInfoBar, NavigationBar } from "components/LandingPage";
import styles from "./SSScreen.module.css";

interface Props {
  children: React.ReactNode;
  screenWidth: number;
  noPadding?: boolean;
}

const SSScreenComponent = ({ children, screenWidth, noPadding }: Props) => {
  const finalContentStyle = `${styles.content} ${
    noPadding && styles.contentNoPadding
  }`;
  return (
    <div className={styles.container}>
      <NavigationBar screenWidth={screenWidth} />
      <div className={finalContentStyle}>{children}</div>
      <BottomInfoBar />
    </div>
  );
};

export default SSScreenComponent;
