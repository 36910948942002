import RocketLaunchIcon from "./components/RocketLaunchIcon";

interface NavigationRouteButton {
  id: number;
  label: string;
  Icon?: JSX.Element;
  type: "text" | "button";
  buttonVariant?: "default" | "pink";
  route: string;
}

export const navigationRouteButtons: NavigationRouteButton[] = [
  {
    id: 0,
    label: "Blog",
    type: "text",
    route: "/blog",
  },
  {
    id: 1,
    label: "Learn More",
    Icon: RocketLaunchIcon(),
    type: "button",
    route: "/learn-more",
  },
  /*{
    id: 1,
    label: "How it works",
    type: "button",
    buttonVariant: "pink",
    route: "/how-it-works",
  },
  */
];

export const navigationRouteButtonsTablet: NavigationRouteButton[] = [
  {
    id: 2,
    label: "Learn More",
    Icon: RocketLaunchIcon(),
    type: "button",
    route: "/learn-more",
  },
];
