import { ActionButton } from "components/LandingPage";
import ContactFormInput from "./components/ContactFormInput";
import { Pngs } from "res";
import { ContactFormProps } from "../../types";
import styles from "./ContactForm.module.css";

const CONTACT_FORM_EMAIL = "hello@snapshelf.ai";
const CONTACT_FORM_FULLNAME_PLACEHOLDER = "Full Name";
const CONTACT_FORM_SUBMIT_BUTTON_TEXT = "Submit";

interface Props {
  contactFormProps: ContactFormProps;
  style?: string;
}

const ContactForm = ({ contactFormProps, style }: Props) => {
  const finalStyle = `${styles.form} ${style}`;

  return (
    <div className={finalStyle}>
      <div className={styles.formColumn}>
        <div className={styles.formColumnRow}>
          <img
            className={styles.icon}
            src={Pngs.contactIcons.envelopeIcon}
            alt="email"
          />
          <div className={styles.email} onClick={contactFormProps.onEmailPress}>
            {CONTACT_FORM_EMAIL}
          </div>
        </div>
      </div>

      <div className={`${styles.formColumn} ${styles.lastFormColumn}`}>
        <ContactFormInput
          style={styles.textInput}
          placeholder={CONTACT_FORM_FULLNAME_PLACEHOLDER}
          icon={Pngs.contactIcons.userIcon}
          value={contactFormProps.form.fullName}
          onTextChange={contactFormProps.onFullNameTextChange}
          error={contactFormProps.formError}
        />
        <div className={styles.formError}>{contactFormProps.formError}</div>
        <ActionButton
          variant="green"
          style={styles.submit}
          label={CONTACT_FORM_SUBMIT_BUTTON_TEXT}
          onClick={contactFormProps.onSubmitPress}
        />
      </div>
    </div>
  );
};

export default ContactForm;
