import { useDispatch } from "react-redux";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import authReducer, { AuthState } from "./slices/auth/authReducer";

export interface IRootState {
  auth: AuthState;
}

const reducer = combineReducers({
  auth: authReducer,
});

const store = configureStore({
  reducer,
});

export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export default store;
