import styles from "./TextButton.module.css";

interface Props {
  label: string;
  onClick: () => void;
  style?: string;
  labelStyle?: string;
}

const TextButton = ({ label, onClick, style, labelStyle }: Props) => {
  const finalStyle = `${styles.container} ${style}`;
  const finalLabelStyle = `${styles.label} ${labelStyle}`;

  return (
    <div className={finalStyle} onClick={onClick}>
      <div className={finalLabelStyle}>{label}</div>
    </div>
  );
};

export default TextButton;
