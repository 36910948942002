import { ExplainBlockVariant } from "../../../../types";
import RatingStars from "./components/RatingStars";
import styles from "./ExplainContent.module.css";

interface Props {
  variant: ExplainBlockVariant;
  explain: string;
  stars?: number;
}

const ExplainContent = ({ variant, explain, stars }: Props) => {
  const finalExplainStyle = `${styles.explain} ${
    variant === "ratings" && styles.explainRating
  }`;
  return (
    <div className={styles.container}>
      <div className={finalExplainStyle}>{explain}</div>
      {stars && <RatingStars stars={stars} />}
    </div>
  );
};

export default ExplainContent;
