// explain
const easyToUseIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/easyToUse.png";
const superAccurateIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/superAccurate.png";
const freeIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/free.png";

// ratings
const noahIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/ratings/noah.png";
const beaIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/ratings/bea.png";
const josiahIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/ratings/josiah.png";

const starIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/ratings/star.png";
const starEmptyIcon =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/explain/ratings/star_empty.png";

export const ExplainIcons = {
  explain: {
    easyToUseIcon,
    superAccurateIcon,
    freeIcon,
  },
  ratings: {
    noahIcon,
    beaIcon,
    josiahIcon,
  },
  stars: {
    starIcon,
    starEmptyIcon,
  },
};
