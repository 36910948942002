import ContactForm from "./components/ContactForm";
import { ContactFormProps } from "./types";
import styles from "./GetInTouch.module.css";

const GET_IN_TOUCH_TITLE = "Let’s get in touch!";
const GET_IN_TOUCH_SUBTITLE =
  "Have questions about SnapShelf? Our team is here to help. Contact us for quick and friendly support.";

const GetInTouchComponent = (contactFormProps: ContactFormProps) => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <div className={styles.title}>{GET_IN_TOUCH_TITLE}</div>
        <div className={styles.subtitle}>{GET_IN_TOUCH_SUBTITLE}</div>
        <ContactForm style={styles.form} contactFormProps={contactFormProps} />
      </div>
    </div>
  );
};

export default GetInTouchComponent;
