import { SSScreen } from "components/LandingPage";
import styles from "./PrivacyPolicyScreen.module.css";

const PrivacyPolicyScreen = () => {
  return (
    <SSScreen>
      <div className={styles.container}>
        <p>
          <b>Privacy Policy for Snapshelf.ai</b>
        </p>
        <p>
          <span>Effective Date: 22 December 2023</span>
        </p>

        <b>1. Introduction</b>

        <p>
          <span>
            Welcome to Snapshelf.ai! This Privacy Policy explains how we
            collect, use, disclose, and safeguard your personal information when
            you use our mobile application, Snapshelf.ai ("App"). By using the
            App, you consent to the practices described in this Privacy Policy.
          </span>
        </p>
        <b>2. Information We Collect</b>
        <p>
          <span> </span>
          <b>Information You Provide:</b>
          <span>
            {" "}
            We may collect information you provide when using our App, including
            but not limited to:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Registration information: Your name, email address, and password.
            </span>
          </li>
          <li>
            <span>
              User-generated content: Content you create, upload, or share on
              the App.
            </span>
          </li>
          <li>
            <span>
              Communication: Your communication with us through the App or
              email.
            </span>
          </li>
        </ul>
        <p>
          <span> </span>
          <b>Automatically Collected Information:</b>
          <span>
            {" "}
            We may automatically collect certain information when you use the
            App, such as:
          </span>
        </p>
        <ul>
          <li>
            <span>
              Device information: Device type, operating system, unique device
              identifiers, and mobile network information.
            </span>
          </li>
          <li>
            <span>
              Usage data: Information about your interactions with the App,
              including pages viewed, features used, and the date and time of
              your visits.
            </span>
          </li>
        </ul>
        <b>3. How We Use Your Information</b>
        <p>
          <span>We may use your information for the following purposes:</span>
        </p>
        <ul>
          <li>
            <span>To provide and maintain the App's functionality.</span>
          </li>
          <li>
            <span>To personalize your experience on the App.</span>
          </li>
          <li>
            <span>To communicate with you and respond to your inquiries.</span>
          </li>
          <li>
            <span>To send you notifications and updates about the App.</span>
          </li>
          <li>
            <span>
              To analyze and improve the App's performance and user experience.
            </span>
          </li>
          <li>
            <span>
              To protect our legal rights and prevent misuse of the App.
            </span>
          </li>
        </ul>
        <b>4. Sharing Your Information</b>
        <p>
          <span>
            We may share your information in the following circumstances:
          </span>
        </p>
        <ul>
          <li>
            <span>
              With third-party service providers who assist us in operating the
              App.
            </span>
          </li>
          <li>
            <span>
              With third-party integration partners for seamless user
              experience.
            </span>
          </li>
          <li>
            <span>To comply with legal obligations or protect our rights.</span>
          </li>
        </ul>
        <b>5. Your Choices</b>
        <ol>
          <li>
            <b>Account Information:</b>
            <span>
              {" "}
              You can update your account information by accessing your account
              settings in the App.
            </span>
          </li>
          <li>
            <b>Communication:</b>
            <span>
              You can opt out of receiving non-essential communications from us
              by following the unsubscribe instructions provided in those
              communications.
            </span>
          </li>
        </ol>
        <b>6. Data Security</b>
        <p>
          We take reasonable measures to protect your information from
          unauthorized access, disclosure, or alteration. However, no data
          transmission over the internet or method of electronic storage is
          entirely secure, and we cannot guarantee absolute security.
        </p>
        <b>7. Changes to this Privacy Policy</b>
        <p>
          We may update this Privacy Policy to reflect changes in our data
          practices. We will notify you of any material changes through the App.
          Your continued use of the App after such changes constitutes
          acceptance of the updated Privacy Policy.
        </p>
        <b>8. Contact Us</b>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us at support@snapshelf.ai.
        </p>
      </div>
    </SSScreen>
  );
};

export default PrivacyPolicyScreen;
