import { useState } from "react";
import styles from "./ContactFormInput.module.css";

interface Props {
  icon: string;
  value: string;
  placeholder: string;
  onTextChange: (text: string) => void;
  error?: string;
  style?: string;
}

const ContactFormInput = ({
  icon,
  value,
  placeholder,
  onTextChange,
  error,
  style,
}: Props) => {
  const [isFocused, setIsFocused] = useState(false);
  const finalStyle = `${styles.container} ${
    isFocused && styles.containerFocused
  } ${error && styles.containerErrored} ${style}`;

  const onFocus = () => setIsFocused(true);

  const onBlur = () => setIsFocused(false);

  return (
    <div className={finalStyle}>
      <img className={styles.icon} src={icon} alt="text input icon" />
      <div className={styles.divider} />
      <input
        className={styles.input}
        placeholder={placeholder}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        onChange={(e) => onTextChange(e.target.value)}
      />
    </div>
  );
};

export default ContactFormInput;
