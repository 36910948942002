import { useWindowDimensions } from "hooks";
import SSScreenComponent from "./SSScreen.component";

interface Props {
  children: React.ReactNode;
  noPadding?: boolean;
}

const SSScreen = (props: Props) => {
  const { width } = useWindowDimensions();
  return <SSScreenComponent {...props} screenWidth={width} />;
};

export default SSScreen;
