import Axios, {
  AxiosError,
  AxiosRequestConfig,
  AxiosResponse,
  Method,
} from "axios";

const BASE_URL = process.env.REACT_APP_API_URL || "http://192.168.1.124:4000";

export interface ICustomError {
  status: number;
  message: string;
}

class CustomError extends Error {
  constructor(public status: number, message: string) {
    super(message);
  }
}

export class API {
  static async call(
    method: Method,
    input: string,
    init: AxiosRequestConfig,
    auth: boolean = true,
    isMedia: boolean = false
  ) {
    try {
      const contentType = !isMedia ? "application/json" : "multipart/form-data";
      const configHeaders = Object.assign({
        "content-type": contentType,
      });
      const config: AxiosRequestConfig = Object.assign({}, { method }, init);

      const response = await Axios(input, {
        headers: configHeaders,
        baseURL: BASE_URL,
        ...config,
      });

      const json = response.data;
      return json;
    } catch (err: any) {
      const axiosError = err as AxiosError;
      if (
        axiosError.request &&
        (axiosError.code === "ERR_NETWORK" ||
          axiosError.code === "ECONNABORTED")
      ) {
        throw new Error("Could not reach server");
      } else if (axiosError.response) {
        const response: AxiosResponse = err.response;
        const statusCode = response.status;
        const errorMessage = response.data.error;
        if (statusCode === 403) {
        }
        throw new CustomError(statusCode, errorMessage);
      } else {
        throw new Error(err);
      }
    }
  }

  static get(input: string, init: AxiosRequestConfig, auth: boolean = true) {
    return API.call("GET", input, init, auth);
  }

  static put(input: string, init: AxiosRequestConfig, auth: boolean = true) {
    return API.call("PUT", input, init, auth);
  }

  static async post(
    input: string,
    init: AxiosRequestConfig,
    auth: boolean = true,
    isMedia: boolean = false
  ) {
    return API.call("POST", input, init, auth, isMedia);
  }

  static delete(input: string, init: AxiosRequestConfig, auth: boolean = true) {
    return API.call("DELETE", input, init, auth);
  }
}
