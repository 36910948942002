import { SSScreen } from "components/LandingPage";
import styles from "./Blog.module.css";

const ERIC_NAME = "Eric F.";
const ERIC_AVATAR =
  "https://snapshelf.s3.us-west-1.amazonaws.com/blog/eric_avatar.png";
const ERIC_BLOG_TIME = "3 minute read•2 days ago";

const ERIC_BLOG_CHILDREN_OF_TIME_URL = "https://amzn.to/3HEWdDm";
const ERIC_BLOG_BLINDSIGHT_URL = "https://amzn.to/4bepouQ";
const ERIC_BLOG_SPEAKER_FOR_THE_DEAD_URL = "https://amzn.to/3ScTLJu";

const Blog = () => {
  const onChildrenOfTimeClick = () => {
    window.open(ERIC_BLOG_CHILDREN_OF_TIME_URL, "_blank");
  };
  const onBlindSightClick = () => {
    window.open(ERIC_BLOG_BLINDSIGHT_URL, "_blank");
  };
  const onSpeakerForTheDeadClick = () => {
    window.open(ERIC_BLOG_SPEAKER_FOR_THE_DEAD_URL, "_blank");
  };

  return (
    <SSScreen>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.title}>
            The Top 3 First-Contact Novels of All Time
          </div>
          <div className={styles.infoContainer}>
            <img src={ERIC_AVATAR} width={50} height={50} alt="" />
            <div className={styles.userTimeInfoContainer}>
              <div className={styles.userName}>{ERIC_NAME}</div>
              <div className={styles.time}>{ERIC_BLOG_TIME}</div>
            </div>
          </div>
          <div className={styles.body}>
            <p>
              You love aliens. And spaceships. And time travel. That’s why
              you’re here. I love them too.
            </p>
            <p>
              One of my favorite trends in sci-fi right now is that authors are
              re-imagining the “First Contact” narrative. We’re all familiar
              with first contact stories where humans encounter aliens for the
              first time. Often the aliens are humanoid in their physical
              features, there are little-to-no barriers in communication between
              them and us, and they have priorities that are understandable to
              us. In short, when we imagine them we imagine a simulacra of
              ourselves.
            </p>
            <p>
              But does this make sense? Why should alien lifeforms be like us at
              all? Why would they not be as different from humans as bacteria,
              or fungus, or octopuses? Many of the best authors today wrestle
              with the very probable challenges of first contact with a truly
              alien lifeform. And I’m here for it.
            </p>
            <p>
              If you love a first-contact story, here are some of the best–and
              most honest–you’ll find:{" "}
            </p>
            <ol>
              <li>
                <div
                  className={styles.highlightedText}
                  onClick={onChildrenOfTimeClick}
                >
                  Children of Time
                </div>{" "}
                by Adrian Tchaikovsky. This three-part series is packed with
                deeply imaginative musings on cross-species communication. It’s
                everything you’d expect from a man who studied zoology and
                psychology, and it doesn’t disappoint.
              </li>
              <li>
                <div
                  className={styles.highlightedText}
                  onClick={onBlindSightClick}
                >
                  Blindsight
                </div>{" "}
                by Peter Watts. What happens when the aliens you meet have no
                apparent interest in meeting you back? Intriguing, cerebral, and
                darkly terrifying; Blindsight is not to be missed.
              </li>
              <li>
                <div
                  className={styles.highlightedText}
                  onClick={onSpeakerForTheDeadClick}
                >
                  Speaker for the Dead
                </div>{" "}
                by Orson Scott Card. Five species, all with different
                priorities, are intricately connected in the battle for
                survival. Speaker for the Dead is an ambitious expansion on the
                Ender’s Game saga from master-storyteller Orson Scott Card.{" "}
              </li>
            </ol>
            <p>
              I hope you enjoy reading (or maybe re-reading) these books as much
              as I did. If you love aliens, spaceships, and time travel I
              promise these will not disappoint.{" "}
            </p>
          </div>
        </div>
      </div>
    </SSScreen>
  );
};

export default Blog;
