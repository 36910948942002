import { createSlice } from "@reduxjs/toolkit";
import {
  EmailVerificationState,
  emailVerificationInitialState,
  emailVerificationReducers,
} from "./emailVerification/emailVerificationReducer";

export interface AuthState {
  emailVerification: EmailVerificationState;
}

const initialState: AuthState = {
  emailVerification: emailVerificationInitialState,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {},
  // "builder callback API", recommended for TypeScript users
  extraReducers: (builder) => {
    emailVerificationReducers(builder);
  },
});

export default authSlice.reducer;
