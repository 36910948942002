import { useState } from "react";
import GetInTouchComponent from "./GetInTouch.component";

const GetInTouch = () => {
  const [form, setForm] = useState({ fullName: "" });
  const [formError, setFormError] = useState<string | undefined>(undefined);

  const onFullNameTextChange = (text: string) => {
    if (formError) {
      setFormError(undefined);
    }

    setForm((oldForm) => ({ ...oldForm, fullName: text }));
  };

  const onSubmitPress = () => {
    if (!form.fullName) {
      setFormError("Full name field is required");
    } else {
      const subject = encodeURIComponent(form.fullName);
      const mailToLink = "mailto:hello@snapshelf.ai?subject=" + subject;
      window.location.href = mailToLink;
    }
  };

  const onEmailPress = () => {
    window.location.href = `mailto:hello@snapshelf.ai`;
  };

  return (
    <GetInTouchComponent
      form={form}
      formError={formError}
      onFullNameTextChange={onFullNameTextChange}
      onSubmitPress={onSubmitPress}
      onEmailPress={onEmailPress}
    />
  );
};

export default GetInTouch;
