import ExplainHeader from "./components/ExplainHeader";
import ExplainContent from "./components/ExplainContent";
import { ExplainBlock } from "../../types";
import styles from "./AppExplainBlock.module.css";

const AppExplainBlock = ({
  variant = "explain",
  title,
  subtitle,
  explain,
  icon,
  stars,
}: ExplainBlock) => {
  const finalContainerStyle = `${styles.container} ${
    variant === "ratings" && styles.containerRating
  }`;

  return (
    <div className={finalContainerStyle}>
      <ExplainHeader title={title} subtitle={subtitle} icon={icon} />
      <ExplainContent variant={variant} explain={explain} stars={stars} />
    </div>
  );
};

export default AppExplainBlock;
