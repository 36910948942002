import { useCallback } from "react";
import { Pngs } from "res";
import styles from "./RatingStars.module.css";

interface Props {
  stars: number;
}

const RatingStars = ({ stars }: Props) => {
  const renderStars = useCallback((starsCount: number) => {
    const starElements = [];
    for (let i = 0; i < 5; i++) {
      const finalPng =
        i < starsCount
          ? Pngs.ExplainIcons.stars.starIcon
          : Pngs.ExplainIcons.stars.starEmptyIcon;
      starElements.push(
        <img
          key={`star-index-${i}`}
          className={styles.star}
          src={finalPng}
          alt="star"
        />
      );
    }

    return starElements;
  }, []);
  return <div className={styles.starsContainer}>{renderStars(stars)}</div>;
};

export default RatingStars;
