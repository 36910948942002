import styles from "./ActionButton.module.css";

interface Props {
  variant?: "default" | "pink" | "green";
  label: string;
  Icon?: React.ReactNode;
  onClick: () => void;
  style?: string;
  labelStyle?: string;
}

const ActionButton = ({
  variant = "default",
  label,
  Icon,
  onClick,
  style,
  labelStyle,
}: Props) => {
  const finalContainerStyle = `${styles.container} ${
    variant === "pink" && styles.containerPink
  } ${variant === "green" && styles.containerGreen} ${style}`;
  const finalLabelStyle = `${styles.label} ${
    variant === "pink" && styles.labelPink
  } ${labelStyle}`;

  return (
    <div className={finalContainerStyle} onClick={onClick}>
      {Icon && <div className={styles.iconContainer}>{Icon}</div>}
      <div className={finalLabelStyle}>{label}</div>
    </div>
  );
};

export default ActionButton;
