// Import the functions you need from the SDKs you need
import { FirebaseOptions, initializeApp } from "firebase/app";
import { Analytics, getAnalytics, logEvent } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig: FirebaseOptions = {
  apiKey: "AIzaSyCo8ImAtTW8j5ZR-IKxnlGjBBIQWt0UEtc",
  authDomain: "snapshelf-web.firebaseapp.com",
  projectId: "snapshelf-web",
  storageBucket: "snapshelf-web.appspot.com",
  messagingSenderId: "781089704621",
  appId: "1:781089704621:web:8595d3dacf574049c85424",
  measurementId: "G-T484JHT95V",
};

let analytics: Analytics;

const isProduction = process.env.REACT_APP_ENV === "production";
if (isProduction) {
  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  analytics = getAnalytics(app);
}

export const firebaseLogEvent = (event: string) => {
  if (analytics) {
    logEvent(analytics, event);
  }
};
