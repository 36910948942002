import styles from "./ExplainHeader.module.css";

interface Props {
  title: string;
  subtitle?: string;
  icon: string;
}

const ExplainHeader = ({ title, subtitle, icon }: Props) => {
  return (
    <div className={styles.container}>
      <img className={styles.icon} src={icon} alt="" />
      <div className={styles.title}>{title}</div>
      {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
    </div>
  );
};

export default ExplainHeader;
