import { CompaniesLogos } from "./pngs/landingPage/companiesLogos";
import { contactIcons } from "./pngs/landingPage/contact";
import { downloadCTAIcons } from "./pngs/landingPage/downloadCTAIcons";
import { ExplainIcons } from "./pngs/landingPage/explainIcons";
import MobileFrame from "./pngs/mobile_frame.png";
import SSBigLogo from "./pngs/ssBigLogo.png";

const IntroImage =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/intro_image.png";
const IntroImage2 =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/landing-page/intro_image2.png";

const AppStoreLogo =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/apple_store.png?v=2";

const GooglePlayLogo =
  "https://snapshelf.s3.us-west-1.amazonaws.com/assets/google_play.png?v=2";

export const Pngs = {
  AppStoreLogo,
  CompaniesLogos,
  contactIcons,
  ExplainIcons,
  GooglePlayLogo,
  downloadCTAIcons,
  IntroImage,
  IntroImage2,
  MobileFrame,
  SSBigLogo,
};
