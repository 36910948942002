import { OrbitProgress } from "react-loading-indicators";

interface Props {
  size?: number;
  style?: React.CSSProperties;
}

const LoadingSpinner = ({ size = 20, style }: Props) => {
  const finalSize = `${size}px`;
  const finalStyle = Object.assign(
    {},
    {
      height: finalSize,
      width: finalSize,
      color: "#a0c5c9",
      fontSize: "10px",
    },
    style
  );

  return <OrbitProgress style={finalStyle} />;
};

export default LoadingSpinner;
