import { AppDownloadButtons } from "components/LandingPage";
import { Pngs } from "res";
import { CTAtexts } from "../../constants";
import styles from "./DownloadCTAMedium.module.css";

const DownloadCTAMedium = () => {
  return (
    <div className={styles.container}>
      <img
        className={styles.background}
        src={Pngs.downloadCTAIcons.downloadCTAMediumIcon}
        alt="CTA"
      />
      <div className={styles.content}>
        <div className={styles.title}>{CTAtexts.title}</div>
        <div className={styles.subtitle}>{CTAtexts.subtitle}</div>
        <AppDownloadButtons style={styles.downloadButtons} />
      </div>
    </div>
  );
};

export default DownloadCTAMedium;
