import { useMemo } from "react";
import DownloadCTABig from "./components/DownloadCTABig";
import styles from "./DownloadCTA.module.css";
import DownloadCTAMedium from "./components/DownloadCTAMedium";
import DownloadCTASmall from "./components/DownloadCTASmall";

interface Props {
  screenWidth: number;
}

const DownloadCTA = ({ screenWidth }: Props) => {
  const CTAToRender = useMemo(() => {
    if (screenWidth > 1280) {
      return <DownloadCTABig />;
    } else if (screenWidth > 800) {
      return <DownloadCTAMedium />;
    } else {
      return <DownloadCTASmall />;
    }
  }, [screenWidth]);

  return <div className={styles.container}>{CTAToRender}</div>;
};

export default DownloadCTA;
