import { SSScreen } from "components/LandingPage";
import styles from "./TOSScreen.module.css";

const TOSScreen = () => {
  return (
    <SSScreen>
      <div className={styles.container}>
        <p>
          <b>Section 1: Introduction</b>
        </p>
        <p>
          Welcome to Snapshelf.ai! These Terms of Service ("Terms") govern your
          use of the Snapshelf.ai mobile application ("App") and the services
          provided through it. By accessing or using our App, you agree to
          comply with and be bound by these Terms. If you do not agree with
          these Terms, please do not use our App.
        </p>
        <p>
          These Terms may be updated from time to time, and you can find the
          most current version on our website. Your continued use of the App
          after any changes to the Terms constitute your acceptance of those
          changes.
        </p>
        <p>
          We value your privacy and the security of your personal information.
          Please review our Privacy Policy, which explains how we collect, use,
          and protect your data. Your use of the App is also governed by our
          Privacy Policy.
        </p>
        <p>
          <b>Section 2: Acceptance of Terms</b>
        </p>
        <p>
          <span>2.1 </span>
          <b>Agreement to Terms</b>
        </p>
        <ol>
          <li>
            <p>
              By accessing or using the Snapshelf.ai mobile application ("App")
              and the services provided through it, you agree to comply with and
              be bound by these Terms of Service ("Terms"). If you do not agree
              with these Terms, please do not use our App.
            </p>
          </li>
        </ol>
        <p>
          <span>2.2 </span>
          <b>Eligibility</b>
        </p>
        <ol>
          <li>
            <p>
              You must be at least 18 years old to use our App and services. By
              using the App, you represent and warrant that you are of legal age
              to form a binding contract with Snapshelf.ai. If you are under the
              age of 18, please discontinue use of the App immediately.
            </p>
          </li>
        </ol>
        <p>
          <span>2.3 </span>
          <b>Modification of Terms</b>
        </p>
        <ol>
          <li>
            <p>
              Snapshelf.ai reserves the right to modify or revise these Terms at
              any time. Any changes to these Terms will be effective immediately
              upon posting. Your continued use of the App after the posting of
              any changes constitutes your acceptance of those changes.
            </p>
          </li>
        </ol>
        <p>
          <span>2.4 </span>
          <b>Additional Policies</b>
        </p>
        <ol>
          <li>
            <p>
              Your use of the App is also governed by our Privacy Policy, which
              outlines how we collect, use, and protect your data. By using the
              App, you consent to the terms of our Privacy Policy.
            </p>
          </li>
        </ol>
        <p>
          <span>2.5 </span>
          <b>Termination of Use</b>
        </p>
        <ol>
          <li>
            <p>
              Snapshelf.ai reserves the right to terminate or suspend your
              access to the App and services, with or without notice, for any
              violation of these Terms or for any other reason deemed necessary
              to protect the integrity and security of the App.
            </p>
          </li>
        </ol>
        <p>
          <b>Section 3: User Registration and Account</b>
        </p>
        <p>
          <span>3.1 </span>
          <b>User Accounts</b>
        </p>
        <ol>
          <li>
            <p>
              To access certain features of Snapshelf.ai, you may be required to
              register for an account. You agree to provide accurate, current,
              and complete information during the registration process and to
              update such information to keep it accurate, current, and
              complete.
            </p>
          </li>
        </ol>
        <p>
          <span>3.2 </span>
          <b>Account Security</b>
        </p>
        <ol>
          <li>
            <p>
              You are responsible for safeguarding your account credentials,
              including your username and password, and for any activities or
              actions under your account. You agree not to disclose your
              password to any third party. You must immediately notify
              Snapshelf.ai of any unauthorized use of your account or any other
              security breaches.
            </p>
          </li>
        </ol>
        <p>
          <span>3.3 </span>
          <b>User Data</b>
        </p>
        <ol>
          <li>
            <p>
              By using the App, you grant Snapshelf.ai the right to access and
              use certain data and information, including but not limited to
              your bookshelf data and user-generated content, as described in
              our Privacy Policy.
            </p>
          </li>
        </ol>
        <p>
          <span>3.4 </span>
          <b>Account Termination</b>
        </p>
        <ol>
          <li>
            <p>
              Snapshelf.ai reserves the right to suspend or terminate your
              account and access to the App and services if you violate these
              Terms or engage in any activity that violates applicable laws or
              regulations.
            </p>
          </li>
        </ol>
        <p>
          <span>3.5 </span>
          <b>Feedback</b>
        </p>
        <ol>
          <li>
            <p>
              We welcome your feedback, suggestions, or ideas regarding the App
              and services. By submitting feedback to Snapshelf.ai, you grant us
              the right to use, implement, and share your feedback for any
              purpose, without any compensation or attribution to you.
            </p>
          </li>
        </ol>
        <p>
          <span>3.6 </span>
          <b>Third-Party Accounts</b>
        </p>
        <ol>
          <li>
            <p>
              You may be able to connect your Snapshelf.ai account to
              third-party accounts, such as social media accounts. By linking
              your Snapshelf.ai account to any third-party accounts, you
              acknowledge and agree that you are authorized to do so and that
              you permit us to access and use your third-party account
              information.
            </p>
          </li>
        </ol>
        <p>
          <b>Section 4: Use of the Service</b>
        </p>
        <p>
          <span>4.1 </span>
          <b>Permitted Use</b>
        </p>
        <ol>
          <li>
            <p>
              You agree to use Snapshelf.ai solely for your personal,
              non-commercial use and in compliance with these Terms, applicable
              laws, and regulations. You shall not use the service in any way
              that violates the rights of others or for any unlawful purpose.
            </p>
          </li>
        </ol>
        <p>
          <span>4.2 </span>
          <b>Content Guidelines</b>
        </p>
        <ol>
          <li>
            <b>User-Generated Content:</b>
            <span>
              You may have the opportunity to submit, post, or share
              user-generated content through Snapshelf.ai. You are solely
              responsible for any content you submit and agree not to submit any
              content that is illegal, obscene, defamatory, invasive of privacy,
              infringing on intellectual property rights, or otherwise
              objectionable.
            </span>
          </li>
          <li>
            <b>Prohibited Content:</b>
            <span>
              You shall not use Snapshelf.ai to upload, transmit, or disseminate
              any content or material that contains malware, viruses, or harmful
              code, or that promotes hate speech, discrimination, or violence.
            </span>
          </li>
        </ol>
        <p>
          <span>4.3 </span>
          <b>Intellectual Property</b>
        </p>
        <ol>
          <li>
            <b>Ownership:</b>
            <span>
              Snapshelf.ai and its licensors retain all rights, title, and
              interest in and to the App, including but not limited to all
              copyrights, trademarks, trade secrets, and other intellectual
              property rights. You acknowledge that you do not acquire any
              ownership rights by using the App.
            </span>
          </li>
          <li>
            <b>User Content:</b>
            <span>
              You retain ownership of the content you submit or upload to
              Snapshelf.ai, and you grant Snapshelf.ai a non-exclusive,
              worldwide, royalty-free license to use, reproduce, distribute, and
              display your user-generated content solely for the purpose of
              providing and improving the services.
            </span>
          </li>
        </ol>
        <p>
          <span>4.4 </span>
          <b>Prohibited Activities</b>
        </p>
        <p>
          <span>You agree not to:</span>
        </p>
        <ol>
          <li>
            <b>Access or Attempt to Access Restricted Areas:</b>
            <span>
              Use any robot, spider, scraper, or other automated means to access
              the App for any purpose without our express written permission.
            </span>
          </li>
          <li>
            <b>Interfere with the Services:</b>
            <span>
              Engage in any action that could disrupt, disable, overburden, or
              impair the proper functioning of Snapshelf.ai.
            </span>
          </li>
          <li>
            <b>Reverse Engineer:</b>
            <span>
              Reverse engineer, decompile, disassemble, or otherwise attempt to
              derive the source code of Snapshelf.ai or any related software.
            </span>
          </li>
          <li>
            <span> </span>
            <b>Impersonate Others:</b>
            <span>
              Misrepresent your identity, impersonate any person or entity, or
              otherwise engage in deceptive online behavior.
            </span>
          </li>
        </ol>
        <p>
          <span>4.5 </span>
          <b>Modifications and Updates</b>
        </p>
        <ol>
          <li>
            <p>
              Snapshelf.ai reserves the right to modify, update, or discontinue
              the App or any feature or aspect of the services at any time
              without notice. We may also update these Terms from time to time.
              Your continued use of the App after such changes constitutes
              acceptance of the updated Terms.
            </p>
          </li>
        </ol>
        <p>
          <span>4.6 </span>
          <b>Termination of Service</b>
        </p>
        <ol>
          <li>
            <p>
              Snapshelf.ai reserves the right to terminate or suspend access to
              the App or any part thereof, at our sole discretion, without
              notice, for any reason, including but not limited to a breach of
              these Terms or violations of applicable laws.
            </p>
          </li>
        </ol>
        <p>
          <b>Section 5: User Accounts and Registration</b>
        </p>
        <p>
          <span>5.1 </span>
          <b>Account Creation</b>
        </p>
        <ol>
          <li>
            <span> </span>
            <b>Eligibility:</b>
            <span>
              To use certain features of Snapshelf.ai, you may be required to
              register for an account. By creating an account, you represent and
              warrant that you are at least 18 years old and that all
              information you provide is accurate, current, and complete.
            </span>
          </li>
          <li>
            <b>Account Security:</b>
            <span>
              You are responsible for maintaining the confidentiality of your
              account credentials, including your username and password. You
              agree to notify us immediately of any unauthorized use of your
              account or any other breach of security.
            </span>
          </li>
        </ol>
        <p>
          <span>5.2 </span>
          <b>User Responsibilities</b>
        </p>
        <ol>
          <li>
            <span> </span>
            <b>Accurate Information:</b>
            <span>
              You agree to provide accurate and complete information during the
              registration process and to update such information to ensure its
              accuracy.
            </span>
          </li>
          <li>
            <span> </span>
            <b>Prohibited Actions:</b>
            <span>
              You shall not use another user's account or share your account
              credentials with others. You are solely responsible for all
              activities that occur under your account.
            </span>
          </li>
        </ol>
        <p>
          <span>5.3 </span>
          <b>User Data</b>
        </p>
        <ol>
          <li>
            <b>Data Collection:</b>
            <span>
              Snapshelf.ai may collect and use certain user data as described in
              our Privacy Policy. By using the App, you consent to the
              collection and use of your data in accordance with our Privacy
              Policy.
            </span>
          </li>
          <li>
            <b>User Content:</b>
            <span>
              You acknowledge that Snapshelf.ai may store and retain
              user-generated content you submit to the App, and that such
              content may be used as described in Section 4.3.
            </span>
          </li>
        </ol>
        <p>
          <span>5.4 </span>
          <b>Feedback</b>
        </p>
        <p>
          <span>
            If you choose to submit comments, ideas, or feedback about
            Snapshelf.ai, you agree that we may use such comments, ideas, or
            feedback without any obligation to compensate you for them.
          </span>
        </p>
        <p>
          <b>Section 6: Content and User Conduct</b>
        </p>
        <p>
          <span>6.1 </span>
          <b>User-Generated Content</b>
        </p>
        <ol>
          <li>
            <b>Responsibility:</b>
            <span>
              Snapshelf.ai allows users to submit, upload, or share content,
              including bookshelf images and comments ("User-Generated
              Content"). You are solely responsible for the User-Generated
              Content you provide and the consequences of its transmission or
              publication.
            </span>
          </li>
          <li>
            <b>Ownership:</b>
            <span>
              You retain ownership of your User-Generated Content. However, by
              submitting, uploading, or sharing User-Generated Content on
              Snapshelf.ai, you grant Snapshelf.ai a worldwide, non-exclusive,
              royalty-free license to use, host, store, reproduce, modify,
              create derivative works, communicate, publish, publicly perform,
              publicly display, and distribute such content.
            </span>
          </li>
          <li>
            <span>
              <b>Prohibited Content:</b> You agree not to submit, upload, or
              share User-Generated Content that is unlawful, infringing,
              harassing, defamatory, obscene, fraudulent, deceptive, or
              otherwise objectionable. Snapshelf.ai reserves the right to remove
              or disable access to any User-Generated Content that violates
              these Terms of Service.
            </span>
          </li>
        </ol>
        <p>
          <span>6.2 </span>
          <b>User Conduct</b>
        </p>
        <ol>
          <li>
            <b>Compliance:</b>
            <span>
              While using Snapshelf.ai, you agree to comply with all applicable
              laws and regulations and these Terms of Service.
            </span>
          </li>
          <li>
            <b>Prohibited Activities:</b>
            <span>
              You shall not engage in any of the following activities:
            </span>
          </li>
          <li>
            <span>
              Attempt to gain unauthorized access to Snapshelf.ai, user
              accounts, or computer systems.
            </span>
          </li>
          <li>
            <span>
              Use Snapshelf.ai in any manner that could interfere with, disrupt,
              negatively affect, or inhibit other users from using the service.
            </span>
          </li>
          <li>
            Use automated means, such as bots or crawlers, to collect
            information or interact with Snapshelf.ai, unless explicitly
            permitted by Snapshelf.ai.
          </li>
          <li>
            <span>
              Engage in any activity that violates the privacy, rights, or
              interests of other users.
            </span>
          </li>
          <li>
            <span>
              Impersonate any person or entity, or falsely state or otherwise
              misrepresent your affiliation with a person or entity.
            </span>
          </li>
          <li>
            <span>
              Use Snapshelf.ai for any illegal, unauthorized, or unethical
              purposes.
            </span>
          </li>
        </ol>
        <p>
          <span>6.3 </span>
          <b>Monitoring and Enforcement</b>
        </p>
        <p>
          <span>
            Snapshelf.ai reserves the right to monitor User-Generated Content
            and user conduct on the platform. We may take appropriate action,
            including the removal of content or suspension of accounts, to
            enforce these Terms of Service.
          </span>
        </p>
        <p>
          <span>6.4 </span>
          <b>Third-Party Content</b>
        </p>
        <ol>
          <li>
            <b>Links and Third-Party Content:</b>
            <span>
              Snapshelf.ai may contain links to third-party websites, services,
              or content. We are not responsible for such third-party content,
              and your use of third-party websites or services is subject to
              their respective terms and policies.
            </span>
          </li>
          <li>
            <b>Third-Party Integration:</b>
            <span>
              Snapshelf.ai may integrate with third-party services or platforms.
              Your use of such integrations is subject to the terms and policies
              of those third parties.
            </span>
          </li>
        </ol>
        <p>
          <b>Section 7: Intellectual Property Rights</b>
        </p>
        <p>
          <span>7.1 </span>
          <b>Snapshelf.ai's Rights</b>
        </p>
        <ol>
          <li>
            <b>Ownership:</b>
            <span>
              Snapshelf.ai and its licensors retain all rights, title, and
              interest in and to the Snapshelf.ai platform, including all
              associated intellectual property rights.
            </span>
          </li>
          <li>
            <b>User Restrictions:</b>
            <span> You agree not to, and will not permit others to:</span>
          </li>
          <li>
            <span>
              Use Snapshelf.ai's name, logo, or trademarks without our prior
              written consent.
            </span>
          </li>
          <li>
            <span>
              Reverse engineer, decompile, disassemble, attempt to derive the
              source code of, or decrypt Snapshelf.ai's software or any part
              thereof.
            </span>
          </li>
          <li>
            Copy, modify, distribute, sell, or lease any part of Snapshelf.ai's
            platform or content.
          </li>
        </ol>
        <p>
          <span>7.2 </span>
          <b>User-Generated Content</b>
        </p>
        <ol>
          <li>
            <b>Ownership of User-Generated Content:</b>
            <span>
              You retain ownership of your User-Generated Content, but by
              submitting, uploading, or sharing it on Snapshelf.ai, you grant
              Snapshelf.ai a license to use, as described in Section 6.1.
            </span>
          </li>
          <li>
            <b>Rights to Recommendations:</b>
            <span>
              Any book recommendations generated by Snapshelf.ai based on your
              User-Generated Content are provided for your personal use. You
              agree not to reproduce, distribute, or commercially exploit the
              recommendations without Snapshelf.ai's consent.
            </span>
          </li>
        </ol>
        <p>
          <span>7.3 </span>
          <b>Copyright Infringement</b>
        </p>
        <ol>
          <li>
            <b>Notification:</b>
            <span>
              Snapshelf.ai respects the intellectual property rights of others.
              If you believe that any content on Snapshelf.ai infringes your
              copyright, please notify us in accordance with our Copyright
              Infringement Policy, which can be found on our website.
            </span>
          </li>
          <li>
            <b>Repeat Infringers:</b>
            <span>
              Snapshelf.ai may, at its discretion, terminate the accounts of
              users who repeatedly infringe on copyright or other intellectual
              property rights.
            </span>
          </li>
        </ol>
        <p>
          <b>Section 8: Termination and Account Closure</b>
        </p>
        <p>
          <span>8.1 </span>
          <b>Termination by Snapshelf.ai</b>
        </p>
        <ol>
          <li>
            <b>Grounds for Termination:</b>
            <span>
              Snapshelf.ai reserves the right to terminate or suspend your
              access to the App and services, with or without notice, for any
              violation of these Terms or for any other reason deemed necessary
              to protect the integrity and security of the App.
            </span>
          </li>
          <li>
            <b>Effect of Termination:</b>
            <span>
              Upon termination or suspension, your right to access and use the
              App after such changes constitutes acceptance of the updated
              Terms.
            </span>
          </li>
        </ol>
        <p>
          <b>Section 9: Disclaimers</b>
        </p>
        <p>
          <span>9.1 </span>
          <b>No Warranty</b>
        </p>
        <ol>
          <li>
            <b>As-Is Basis:</b>
            <span>
              You expressly acknowledge and agree that your use of
              Snapshelf.ai's platform and services is at your sole risk. The
              platform and services are provided on an "as-is" and "as
              available" basis. Snapshelf.ai makes no warranty or representation
              regarding the availability, accuracy, completeness, reliability,
              or timeliness of the platform or services.
            </span>
          </li>
          <li>
            <b>No Guarantee of Results:</b>
            <span>
              Snapshelf.ai makes no guarantee that the use of the platform or
              services will achieve any specific results, meet your
              requirements, or be error-free. Any content, recommendations, or
              information provided through the platform are for informational
              purposes only and should not be considered professional advice.
            </span>
          </li>
        </ol>
        <p>
          <span>9.2 </span>
          <b>Third-Party Content and Links</b>
        </p>
        <ol>
          <li>
            <b>Third-Party Content:</b>
            <span>
              Snapshelf.ai's platform may contain links to third-party websites,
              applications, or services. Snapshelf.ai is not responsible for the
              content, accuracy, or opinions expressed on such third-party sites
              and does not endorse or guarantee the products, services, or
              information offered by third parties.
            </span>
          </li>
          <li>
            <b>Risk of Interacting with Third Parties:</b>
            <span>
              You acknowledge and agree that any interactions with third
              parties, including advertisers or other users, are solely between
              you and such third parties. Snapshelf.ai is not responsible for
              any loss or damage arising from such interactions.
            </span>
          </li>
        </ol>
        <p>
          <span>9.3 </span>
          <b>No Liability for Technical Issues</b>
        </p>
        <ol>
          <li>
            <b>Technical Issues:</b>
            <span>
              Snapshelf.ai does not warrant that the platform will be
              uninterrupted or error-free. We are not liable for any technical
              problems, including but not limited to server downtime, data loss,
              or transmission errors.
            </span>
          </li>
        </ol>
        <p>
          <span>9.4 </span>
          <b>Limitation of Liability</b>
        </p>
        <ol>
          <li>
            <b>Exclusion of Damages:</b>
            <span>
              To the fullest extent permitted by applicable law, Snapshelf.ai
              and its affiliates, officers, directors, employees, agents, and
              licensors shall not be liable for any indirect, incidental,
              special, consequential, or punitive damages, or any loss of
              profits or revenues, whether incurred directly or indirectly, or
              any loss of data, use, goodwill, or other intangible losses,
              arising out of your access to or use of the platform or services.
            </span>
          </li>
          <li>
            <b>Maximum Liability:</b>
            <span>
              In no event shall Snapshelf.ai's total liability to you for all
              claims arising under or relating to these Terms of Service or your
              use of the platform exceed the amount you have paid to
              Snapshelf.ai, if any, in the past six months.
            </span>
          </li>
        </ol>
        <p>
          <b>Section 10: Termination and Suspension</b>
        </p>
        <p>
          <span>10.1 </span>
          <b>Termination by Snapshelf.ai</b>
        </p>
        <ol>
          <li>
            <b>Termination for Convenience:</b>
            <span>
              Snapshelf.ai reserves the right to terminate, modify, or suspend
              your access to the platform or services, or any part thereof, at
              our sole discretion and without prior notice. This may include,
              but is not limited to, situations where you violate these Terms of
              Service, engage in fraudulent or unlawful activities, or for any
              other reason.
            </span>
          </li>
          <li>
            <b>Effect of Termination:</b>
            <span>
              Upon termination or suspension, your right to access and use the
              platform or services will cease immediately. Any provisions of
              these Terms that by their nature should survive termination shall
              survive, including but not limited to Sections 7 (Intellectual
              Property Rights), 9 (Disclaimers), and 10 (Termination and
              Suspension).
            </span>
          </li>
        </ol>
        <p>
          <span>10.2 </span>
          <b>Termination by User</b>
        </p>
        <ol>
          <li>
            <b>Account Closure:</b>
            <span>
              You may terminate your account at any time by discontinuing use of
              the platform and following the account closure instructions
              provided by Snapshelf.ai.
            </span>
          </li>
        </ol>
        <p>
          <b>Section 11: Governing Law and Dispute Resolution</b>
        </p>
        <p>
          <span>11.1 </span>
          <b>Governing Law</b>
        </p>
        <p>
          These Terms of Service and your use of the Snapshelf.ai platform and
          services shall be governed by and construed in accordance with the
          laws of [Jurisdiction], without regard to its conflict of law
          principles.
        </p>
        <p>
          <span>11.2 </span>
          <b>Dispute Resolution</b>
        </p>
        <ol>
          <li>
            <b>Negotiation:</b>
            <span>
              In the event of any dispute arising out of or relating to these
              Terms or your use of the platform, the parties shall attempt to
              resolve the dispute through good faith negotiation.
            </span>
          </li>
          <li>
            <b>Binding Arbitration:</b>
            <span>
              If the parties are unable to resolve the dispute through
              negotiation, any disputes, controversies, or claims arising out of
              or relating to these Terms or your use of the platform shall be
              resolved by binding arbitration in accordance with the rules of
              [Arbitration Association].
            </span>
          </li>
          <li>
            <b>Waiver of Class Action:</b>
            <span>
              You and Snapshelf.ai waive any right to bring claims on a
              representative or class-wide basis, and any arbitration
              proceedings shall be conducted on an individual basis.
            </span>
          </li>
        </ol>
        <p>
          <b>Section 12: Miscellaneous</b>
        </p>
        <p>
          <span>12.1 </span>
          <b>Entire Agreement</b>
        </p>
        <ol>
          <li>
            <p>
              These Terms of Service constitute the entire agreement between you
              and Snapshelf.ai with respect to the subject matter herein and
              supersede all prior discussions, understandings, and agreements,
              whether oral or written.
            </p>
          </li>
        </ol>
        <p>
          <span>12.2 </span>
          <b>Severability</b>
        </p>
        <ol>
          <li>
            <p>
              If any provision of these Terms of Service is found to be invalid
              or unenforceable, that provision shall be limited or eliminated to
              the minimum extent necessary so that these Terms shall otherwise
              remain in full force and effect.
            </p>
          </li>
        </ol>
        <p>
          <span>12.3 </span>
          <b>No Waiver</b>
        </p>
        <ol>
          <li>
            <p>
              The failure of Snapshelf.ai to exercise or enforce any right or
              provision of these Terms of Service shall not constitute a waiver
              of such right or provision.
            </p>
          </li>
        </ol>
        <p>
          <span>12.4 </span>
          <b>Assignment</b>
        </p>
        <ol>
          <li>
            <span>
              Snapshelf.ai may assign or transfer these Terms of Service, in
              whole or in part, to any person or entity at any time with or
              without your consent.
            </span>
          </li>
        </ol>
        <p>
          <span>12.5 </span>
          <b>Contact Information</b>
        </p>
        <ol>
          <li>
            <p>
              If you have any questions about these Terms of Service, please
              contact us at support@snapshelf.ai.
            </p>
          </li>
        </ol>
      </div>
    </SSScreen>
  );
};

export default TOSScreen;
