import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import {
  Blog,
  ContactScreen,
  EmailVerificationScreen,
  LandingScreen,
  PrivacyPolicyScreen,
  TOSScreen,
} from "screens/LandingPage";

const MainRouter = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<LandingScreen />} />
        <Route path="/blog" element={<Blog />} />
        <Route
          path="/verify-email/:token"
          element={<EmailVerificationScreen />}
        />
        <Route path="/tos" element={<TOSScreen />} />
        <Route path="/privacy" element={<PrivacyPolicyScreen />} />
        <Route path="/contact-us" element={<ContactScreen />} />
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

export default MainRouter;
