import { SSScreen } from "components/LandingPage";
import AppIntro from "./components/AppIntro";
import CompaniesDisplay from "./components/CompaniesDisplay";
import AppExplain from "./components/AppExplain";
import DownloadCTA from "./components/DownloadCTA";
import GetInTouch from "./components/GetInTouch";
import styles from "./LandingScreen.module.css";

interface Props {
  screenWidth: number;
}

const LandingScreenComponent = ({ screenWidth }: Props) => {
  return (
    <SSScreen noPadding>
      <div className={styles.content}>
        <AppIntro />
        <CompaniesDisplay screenWidth={screenWidth} />
        <AppExplain />
        <AppIntro variant="get-started" />
        <AppExplain variant="ratings" />
        <DownloadCTA screenWidth={screenWidth} />
        <GetInTouch />
      </div>
    </SSScreen>
  );
};

export default LandingScreenComponent;
